<template>
  <div
    class="p-2 relative shadow-xl bg-neutral-100 dark:bg-neutral-900 rounded-md border border-neutral-400 dark:border-neutral-600"
    :class="{ 'pb-12': !noButtons }"
  >
    <NamiDatePickerCalendarHeader
      :month="currentlyViewingMonth"
      :year="currentlyViewingYear"
      @prev="calendarDays?.prev()"
      @next="calendarDays?.next()"
    />
    <NamiDatePickerCalendarDays
      ref="calendarDays"
      :model-value="modelValue"
      v-model:current-month-index="currentMonthIndex"
      @update:model-value="(v) => $emit('update:modelValue', v)"
      :events="events"
      :locale="locale"
    />
    <NamiDatePickerCalendarButtons
      v-if="!noButtons"
      class="absolute z-[1] bottom-2 right-2"
      @reset="$emit('update:modelValue', initialDate)"
      @done="$emit('update:show', false)"
    />
  </div>
</template>

<script setup lang="ts">
import type { Dayjs } from "dayjs";
import type { Event } from "./NamiDatePickerEvent.vue";
import NamiDatePickerCalendarDays from "./NamiDatePickerCalendarDays.vue";
import type { AvailableLanguages } from "~/src/api";

const props = defineProps<{
  modelValue: Dayjs;
  events?: Event[];
  show: boolean;
  locale?: AvailableLanguages;
  noButtons?: boolean;
}>();

defineEmits<{
  (e: "update:modelValue", v: Dayjs): void;
  (e: "update:show", v: boolean): void;
}>();

const initialDate = props.modelValue;
const supportedYears = [2020, 2021, 2022, 2023, 2024, 2025, 2026];

const dayjs = useDayjs();
const currentMonthIndex = ref(
  12 * supportedYears.indexOf(initialDate.year()) + initialDate.month(),
);

const currentlyViewingMonth = computed(() =>
  dayjs()
    .month(currentMonthIndex.value % 12)
    .format("MMMM"),
);

const currentlyViewingYear = computed(() =>
  dayjs()
    .year(supportedYears[Math.floor(currentMonthIndex.value / 12)])
    .year(),
);

const calendarDays = ref<InstanceType<typeof NamiDatePickerCalendarDays>>();
</script>
