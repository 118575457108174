<template>
  <div
    class="grid grid-cols-7 gap-2 my-2 text-neutral-700 dark:text-neutral-400"
  >
    <span v-for="dayName in dayNames" class="mx-auto">
      {{ dayName }}
    </span>
  </div>
  <Swiper
    class="w-[17rem]"
    :initialSlide="currentMonthIndex"
    @slideChange="
      (swiper) => $emit('update:currentMonthIndex', swiper.realIndex)
    "
    @swiper="(swiper) => (swiperClass = swiper)"
  >
    <SwiperSlide v-for="month in allDays.flat()">
      <div class="grid gap-2 grid-cols-7">
        <template v-for="day in month">
          <div v-if="!day" />
          <component
            v-else
            :is="day.events.length > 0 ? Tooltip : 'div'"
            fixed
            no-mobile
            size="xlarge"
            class="flex items-center justify-center h-8 relative w-full rounded-full transition-colors"
            :class="{
              'bg-nami-comi-blue text-black':
                day.value === currentlySelectedFormattedDate,
              'hover:bg-neutral-200 dark:hover:bg-neutral-700':
                day.value !== currentlySelectedFormattedDate,
              'cursor-pointer': day,
            }"
            @click="$emit('update:modelValue', day.date)"
          >
            <template v-if="day.events.length > 0" #tooltip>
              <NamiDatePickerEvent :date="day.date" :events="day.events" />
            </template>
            <span>
              {{ day?.text }}
            </span>
            <div
              v-if="day.events.length > 0"
              class="absolute left-0 bottom-[0.125rem] w-full mt-1 flex justify-center items-center"
            >
              <IconCircleFilled
                v-for="_ in Math.min(2, day.events.length)"
                :class="{
                  'text-black': day.value === currentlySelectedFormattedDate,
                  'text-nami-comi-blue':
                    day.value !== currentlySelectedFormattedDate,
                }"
                :size="6"
              >
              </IconCircleFilled>
            </div>
          </component>
        </template>
      </div>
    </SwiperSlide>
  </Swiper>
</template>

<script setup lang="ts">
import { IconCircleFilled } from "@tabler/icons-vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Swiper as SwiperClass } from "swiper";
import "swiper/css";
import type { Dayjs } from "dayjs";
import Tooltip from "~/components/Tooltip.vue";
import type { AvailableLanguages } from "~/src/api";
import type { Event } from "./NamiDatePickerEvent.vue";

const props = defineProps<{
  modelValue: Dayjs;
  events?: Event[];
  locale?: AvailableLanguages;
  currentMonthIndex: number;
}>();

defineEmits<{
  (e: "update:modelValue", v: Dayjs): void;
  (e: "update:currentMonthIndex", v: number): void;
}>();

const dayjs = useDayjs();

const dayjsMappedEvents = computed(
  () =>
    props.events?.map((event) => ({
      ...event,
      date: dayjs.isDayjs(event.date) ? event.date : dayjs(event.date),
    })) ?? [],
);

const dayNames = computed(() =>
  [1, 2, 3, 4, 5, 6, 7].map((day) => dayjs().day(day).format("dd")),
);
const years = [2020, 2021, 2022, 2023, 2024, 2025, 2026];

const allDays = computed(() =>
  years.map((year) => {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
      const date = dayjs(`${year}-${month.toString().padStart(2, "0")}-01`);

      const monthOffset = date.day() === 0 ? 6 : date.day() - 1;

      return Array(42)
        .fill(null)
        .map((_, index) => {
          if (index < monthOffset) return null;
          if (index - monthOffset + 1 > date.daysInMonth()) return null;

          const day = index - monthOffset + 1;
          const currentDate = dayjs(date.date(day));

          const events = dayjsMappedEvents.value.filter((event) => {
            return (
              event.date.year() === year &&
              event.date.month() + 1 === month &&
              event.date.date() === day
            );
          });

          return {
            text: day.toString(),
            value: currentDate.format("YYYY-MM-DD"),
            date: currentDate,
            events,
          };
        });
    });
  }),
);

const currentlySelectedFormattedDate = computed(() =>
  props.modelValue.format("YYYY-MM-DD"),
);

const swiperClass = ref<SwiperClass>();

defineExpose({
  next: () => swiperClass.value?.slideNext(),
  prev: () => swiperClass.value?.slidePrev(),
});
</script>
