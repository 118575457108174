<template>
  <div class="relative" :class="{ disabled, 'inline-block': $slots.activator }">
    <NamiDatePickerButton
      :current-date="modelValue"
      :label="label"
      @show="showDatePicker = true"
      ref="showDatePickerButton"
    >
      {{ modelValue.format("MMMM DD, YYYY") }}
      <template v-if="$slots.activator" #activator="{ open }">
        <slot name="activator" v-bind="{ open }"></slot>
      </template>
    </NamiDatePickerButton>
    <TransitionFade>
      <div
        v-show="showDatePicker"
        ref="datePickerContainer"
        class="absolute z-50 left-1/2 -translate-x-1/2"
        :class="{
          'bottom-14': position === 'top',
          'top-16': position === 'bottom',
        }"
      >
        <NamiDatePickerCalender
          :events="events"
          v-model:show="showDatePicker"
          :model-value="modelValue"
          @update:model-value="(v) => $emit('update:modelValue', v)"
          :locale="locale"
        />
      </div>
    </TransitionFade>
  </div>
</template>

<script setup lang="ts">
import type { Event } from "./NamiDatePickerEvent.vue";
import type { AvailableLanguages } from "~/src/api";
import type { Dayjs } from "dayjs";
import type NamiDatePickerButtonVue from "./NamiDatePickerButton.vue";

interface Props {
  modelValue: Dayjs;
  locale: AvailableLanguages;
  events?: Event[];
  label?: string;
  disabled?: boolean;
  forcePosition?: "top" | "bottom";
}

interface Events {
  (e: "update:modelValue", v: Dayjs): void;
}

const props = defineProps<Props>();
defineEmits<Events>();

const position = ref<"top" | "bottom">(props.forcePosition || "top");

const datePickerContainer = ref<HTMLDivElement>();
const showDatePickerButton =
  ref<InstanceType<typeof NamiDatePickerButtonVue>>();
const showDatePicker = ref(false);

watch(showDatePicker, (newVal) => {
  const rect = showDatePickerButton.value?.$el.getBoundingClientRect() as
    | DOMRect
    | undefined;
  if (!rect) return;

  if (!props.forcePosition) {
    if (rect.y - 400 - window.scrollY > 0) position.value = "top";
    else position.value = "bottom";
  }

  setTimeout(() => {
    newVal ? registerClickListener() : unregisterClickListener();
  }, 300);
});

function handleClick(e: MouseEvent | TouchEvent) {
  if (!datePickerContainer.value?.children[0]) return;

  if (!isClickInsideElements(e, [datePickerContainer.value])) {
    showDatePicker.value = false;
  }
}

function registerClickListener() {
  document.addEventListener("click", handleClick);
}

function unregisterClickListener() {
  document.removeEventListener("click", handleClick);
}
</script>
